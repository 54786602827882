<template>
    <b-input-group>
        <template v-slot:append>
            <b-button v-on:click="matchRoom">鎖定</b-button>
        </template>
        <b-form-select
                :options="['一宿', '二宿', '忠孝樓']"
                v-model="dorm"
                :state="isFind"
                @change="modify"
        />
        <b-form-input trim placeholder="寢室" @change="modify" v-model="room" :state="isFind"/>
        <b-form-input trim placeholder="床位" @change="modify" v-model="bed" :state="isFind"/>
    </b-input-group>
</template>

<script>
    import axios from "axios";

    export default {
        name: "lockRoom",
        data: function () {
            return {
                dorm: '',
                room: '',
                bed: '',
                isFind: false,
                result: {
                    id: undefined,
                    name: undefined,
                    sex: undefined,
                    usable: undefined,
                    now_bh: undefined
                }
            }
        },
        methods: {
            matchRoom(){
                this.modalShow = true;
                this.room = this.room.toUpperCase()
                this.bed = this.bed.toUpperCase()
                axios.get('/api/room/str2bed', {
                    params: {
                        dorm: this.dorm,
                        room: this.room,
                        bed: this.bed
                    }
                }).then((resp)=>{
                    this.result = resp.data;
                    this.isFind = true;
                }).catch((err) => {
                    this.isFind = false;
                    this.result = {
                        id: undefined,
                        name: undefined,
                        sex: undefined,
                        usable: undefined,
                        now_bh: undefined
                    }
                    if(err.response){
                        if(err.response.status === 404){
                            this.changeBed.target = "";
                            this.changeBed.search.isFind = false;
                            this.changeBed.search.userName = '';
                            this.changeBed.search.sex = '';
                            window.alert('找不到該床位');
                        }
                    }else {
                        window.alert("出現網路問題，無法連線後端。");
                        window.console.log(err);
                    }
                    this.$emit('apiError');
                })
            },
            modify(){
                if(this.isFind === true){
                    this.isFind = false;
                    this.result = {
                        id: undefined,
                        name: undefined,
                        sex: undefined,
                        usable: undefined,
                        now_bh: undefined
                    }
                }
            }
        },
        watch: {
            isFind: function (n) {
                if(n === true){
                    this.$emit('locked', this.result);
                }else if(n === false){
                    window.console.log('raise');
                    this.$emit('unlock');
                }
            }
        }
    }
</script>

<style scoped>

</style>