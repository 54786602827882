<template>
    <div>
        <div>
            <b-card body-class="text-center" header-tag="nav">
                <template v-slot:header>
                    <b-nav card-header tabs>
                        <b-nav-item v-on:click="activeTag = 0" v-bind:active="activeTag === 0">批次上傳</b-nav-item>
                        <b-nav-item v-on:click="activeTag = 1" v-bind:active="activeTag === 1">上傳批次管理</b-nav-item>
                        <b-nav-item v-on:click="activeTag = 2" v-bind:active="activeTag === 2">手動加人</b-nav-item>
                        <b-nav-item v-on:click="activeTag = 3" v-bind:active="activeTag === 3">衝突處理</b-nav-item>
                        <b-nav-item v-on:click="activeTag = 4" v-bind:active="activeTag === 4">匯入繳費名冊</b-nav-item>
                    </b-nav>
                </template>
                <router-view/>
            </b-card>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CustomerManagement",
        data: function () {
            return{
                activeTag: 0
            }
        },
        watch: {
            activeTag: function (newValue) {
                if (newValue === 0){
                    this.$router.replace({name: "adminCMUpload"}).catch(()=>{});
                }else if (newValue === 1){
                    this.$router.replace({name: "adminCMManger"}).catch(()=>{});
                }else if(newValue === 2){
                    this.$router.replace({name: "adminCMAdd"}).catch(()=>{});
                }else if(newValue === 3){
                    this.$router.replace({name: "adminBedHumanProblem"}).catch(()=>{});
                }else if (newValue === 4){
                    this.$router.replace({name: "adminPayment"}).catch(()=>{});
                }
            }
        },
        mounted: function () {
            this.$router.replace({name: "adminCMUpload"}).catch(()=>{});
        }
    }
</script>

<style scoped>

</style>