<template>
    <div>
        <div class="row justify-content-center">
            <b-alert :show="row_data.tags.length > 0">使用者也擁有這些標籤：{{row_data.tags}}</b-alert>
        </div>
        <div class="row justify-content-center">
            <div class="col-md">
                <b-table striped hover :items="row_data.lodging"/>
            </div>
            <div class="col-md">
                <b-table striped hover :items="row_data.pay"/>
            </div>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "UD-PayLodgingMatch",
        data: function () {
            return {
                row_data:{
                    "lodging": [],
                    "pay": [],
                    "tags": []
                }
            }
        },
        methods:{
            f5(){
                let url = '/api/user/getPayAndLodgingHistory/' + this.$route.params.uuid;
                axios.get(url).then((response)=>{
                    let temp;
                    let output = {"lodging": [], "pay": [], "tags": []};
                    for(let item of response.data.lodging){
                        temp = Object.assign(item);
                        if (temp["入住時間"] !== null){
                            temp["入住時間"] = new Date(temp["入住時間"]).toLocaleString()
                        }
                        if (temp["離宿時間"] !== null){
                            temp["離宿時間"] = new Date(temp["離宿時間"]).toLocaleString()
                        }
                        output["lodging"].push(temp)
                    }
                    output.tags = response.data.tags;
                    output.pay = response.data.pay;
                    this.row_data = output
                })
            }
        },
        mounted: function () {
            this.f5();
        },
        watch:{
            '$route' (){
                this.f5();
            }
        },
        beforeRouteUpdate () {
            this.f5();
        }
    }
</script>

<style scoped>

</style>