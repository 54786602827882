<template>
    <div>
        <router-view/>
        <template v-if="isOnThisPage">
            <div class="row justify-content-center">
                <div class="col">
                      <div>住宿名單：</div>
                      <select id="serial" list="serial-options" v-model="onlySerial.serial">
                        <option value="">全部一次打包</option>
                        <option v-bind:key="serial"
                                v-for="serial in onlySerial.serials"
                        >{{serial}}</option>
                      </select>
                      <b-checkbox v-model="onlySerial.only_active"
                                  @click="this.onlySerial.only_active = !this.onlySerial.only_active">點選下載床位名單</b-checkbox>
                      <p>資料位置：<a :href="excelDownloads(this.onlySerial.serial,this.onlySerial.only_active)">按這裡下載</a></p>
                </div>
                <div class="w-100"/>
                <div class="col">
                    <h3>寢室一覽圖</h3>
                    <div class="text-center" v-if="dashboard.loading">
                        <b-spinner type="grow" class="ml-auto"/>
                        <strong>載入資料中…</strong>
                    </div>
                    <div v-else>
                        <b-card bg-variant="light">
                            <b-form-group
                                    label-cols-lg="3"
                                    label="房間概覽"
                                    label-size="lg"
                                    label-class="font-weight-bold pt-0"
                                    class="mb-0"
                            >
                                <b-form-group
                                    label-cols-sm="3"
                                    label="宿舍:"
                                    label-align-sm="right"
                                    label-for="select-dorm"
                                >
                                    <b-form-select id="select-dorm" v-model="dashboard.filter.dorm" :options="dormOption"/>
                                </b-form-group>
                                <b-form-group
                                        label-cols-sm="3"
                                        label="棟:"
                                        label-align-sm="right"
                                        label-for="select-builder"
                                >
                                    <b-form-select id="select-builder" v-model="dashboard.filter.builder" :options="builderOption"/>
                                </b-form-group>
                                <b-form-group
                                        label-cols-sm="3"
                                        label="樓層:"
                                        label-align-sm="right"
                                        label-for="select-floor"
                                >
                                    <b-form-select id="select-floor" v-model="dashboard.filter.floor" :options="floorOption"/>
                                </b-form-group>
                                <b-form-group
                                        label-cols-sm="3"
                                        label="性別:"
                                        label-align-sm="right"
                                        label-for="select-sex"
                                >
                                    <b-form-select id="select-sex" v-model="dashboard.filter.sex" :options="sexOption"/>
                                </b-form-group>
                                <b-form-group
                                        label="每頁顯示"
                                        label-cols-sm="6"
                                        label-cols-md="4"
                                        label-cols-lg="3"
                                        label-align-sm="right"
                                        label-size="sm"
                                        label-for="perPageSelect"
                                        class="mb-0"
                                >
                                    <b-form-select
                                            v-model="dashboard.table.perPage"
                                            id="perPageSelect"
                                            size="sm"
                                            :options="[5, 10 ,15 ,20, 50 ,100]"
                                    ></b-form-select>
                                </b-form-group>
                            </b-form-group>
                        </b-card>
                    </div>
                    <div class="w-100"/>
                    <div class="col">
                        <b-pagination
                                v-model="dashboard.table.currentPage"
                                :total-rows="filteredItems.length"
                                :per-page="dashboard.table.perPage"
                                align="fill"
                                size="sm"
                                class="my-0"
                                last-number
                        />
                    </div>
                    <div class="w-100"/>
                    <div class="col">
                        <b-table
                                striped
                                hover
                                :fields="dashboard.table.fields"
                                :items="filteredItems"
                                :per-page="dashboard.table.perPage"
                                :current-page="dashboard.table.currentPage">
                            <template v-slot:cell(name4human)="row">
                                <router-link :to="{name: 'roomManagementDetails', params: {room_id: row.item.room_id }}" >{{row.value}}</router-link>
                            </template>
                            <template v-slot:cell(status)="row">
                                {{row.item.have_bed - row.item.available_bed}} / {{row.item.have_bed}}
                            </template>
                            <template v-slot:cell(actions)="row">
                                <b-button size="sm" @click="row.toggleDetails">
                                    {{ row.detailsShowing ? '隱藏' : '顯示' }} 詳細資料
                                </b-button>
                            </template>
                            <template v-slot:row-details="row">
                                <b-card>
                                    <p>本房間備註：<br>{{row.item.remark}}</p>
                                    <p>本房間標籤：</p>
                                    <ul>
                                        <li v-for="(value, index) in row.item.tags" :key="index"> {{ value }}</li>
                                    </ul>
                                </b-card>
                            </template>
                        </b-table>
                    </div>

                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "AdminAreaHome",
        data: function(){
            return {
                dashboard:{
                    loading: true,
                    rowData:[
                    ],
                    filter:{
                        dorm: "全部",
                        floor: '全部',
                        sex: '全部',
                        builder: '全部'
                    },
                    table:{
                        perPage: 5,
                        currentPage:1,
                        fields: [
                            {
                                key: 'name4human',
                                label: "房間",
                                sortable: true
                            },
                            {
                                key: 'status',
                                label: "狀態"
                            },
                            {
                                key: 'actions',
                                label: '詳細資料',
                                sortable: false
                            }
                        ]
                    }
                },
                onlySerial:{
                  serials: [],
                  serial: "",
                  only_active: false
                },
            }
        },
        computed: {
            isOnThisPage(){
                return this.$route.path === "/admin"
            },
            dormOption(){
                let dormSet = new Set();
                for(let room of this.dashboard.rowData){
                    dormSet.add(room["dorm"]);
                }
                dormSet.add("全部");
                return Array.from(dormSet)
            },
            sexOption(){
                let sexSet = new Set();
                for(let room of this.dashboard.rowData){
                    sexSet.add(room["sex"]);
                }
                sexSet.add("全部");
                return Array.from(sexSet)
            },
            floorOption(){
                let floorSet = new Set();
                for(let room of this.dashboard.rowData){
                    floorSet.add(room["floor"]);
                }
                floorSet.add("全部");
                return Array.from(floorSet)
            },
            builderOption(){
                let builderSet = new Set();
                for(let room of this.dashboard.rowData){
                    builderSet.add(room["builder"]);
                }
                builderSet.add("全部");
                return Array.from(builderSet)
            },
            filteredItems(){
                let temp;
                temp = this.dashboard.rowData;
                if(this.dashboard.filter.dorm !== "全部"){
                    temp = temp.filter(room => room.dorm === this.dashboard.filter.dorm);
                }
                if(this.dashboard.filter.sex !== "全部"){
                    temp = temp.filter(room => room.sex === this.dashboard.filter.sex);
                }
                if(this.dashboard.filter.floor !== "全部"){
                    temp = temp.filter(room => room.floor === this.dashboard.filter.floor);
                }
                if(this.dashboard.filter.builder !== "全部"){
                    temp = temp.filter(room => room.builder === this.dashboard.filter.builder);
                }
                temp = temp.map(room => {
                    if(room.available_bed === 0){
                        room["_cellVariants"] = {status: "danger"}
                    }else if (room.have_bed === room.available_bed){
                        room["_cellVariants"] = {status: "success"}
                    }else {
                        room["_cellVariants"] = {status: "warning"}
                    }
                    return room
                });
                return temp
            },
        },
        methods: {
          excelDownloads(serial,only_active){
            if(serial == ""){
              return "https://dorm.nttu.club/api/misc/downloadExcel?serial"
            }
            else if(only_active == true) {
              return "https://dorm.nttu.club/api/misc/downloadExcel?serial=" + serial + "&only_active=1"
            }
            else
              return "https://dorm.nttu.club/api/misc/downloadExcel?serial=" + serial
          }

        },
        mounted:function () {
            axios.get('/api/room/listAllRoomStatus')
            .then((response)=>{
                this.dashboard.loading = false;
                this.dashboard.rowData = response.data;
            }).catch((error)=>{
                window.alert("出現錯誤，無法載入資料…");
                if(error.response){
                    window.console.log(error.response.data);
                }else {
                    window.console.log(error.message);
                }
            }),
            axios.get('/api/booking/only_get_bed_history_serial')
                .then((resp)=>{
                  this.onlySerial.serials = resp.data;
                })
                .catch((error)=>{
            if(error.response.status === 400){
              window.alert(error.response.data.msg);
              this.modalShow = false;
            }})
        }
    }
</script>

<style scoped>

</style>