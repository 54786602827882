<template>
    <div class="row justify-content-center">
        <b-modal
                v-model="modalShow"
                no-close-on-esc
                no-close-on-backdrop
                hide-header-close
                hide-footer
                hide-header
        >
            <p class="my-4">系統正在處理中</p>
        </b-modal>
        <div class="col">
            <b-form-group
                    label="在此住宿名單中搜尋"
                    label-for="input-search-CM"

            >
                <b-input-group class="mt-3">
                    <b-input-group-prepend is-text>
                        僅顯示列表：
                    </b-input-group-prepend>
                    <template v-slot:append>
                        <b-button v-on:click="filter">搜尋</b-button>
                    </template>
                    <b-form-select
                            :options="lists"
                            v-model="currentList"
                    />
                </b-input-group>
            </b-form-group>
            <h5>一個人睡兩張床：</h5>
            <b-table striped hover :items="data.one_human_two_bed" :fields="fields">
                <template v-slot:cell(action)="row">
                    <b-button size="sm" variant="danger" @click="deleteBedHistory(row.item.id)">
                        刪除此筆記錄
                    </b-button>
                </template>
            </b-table>
            <h5>兩個人睡一張床：</h5>
            <b-table striped hover :items="data.one_bed_two_human" :fields="fields">
                <template v-slot:cell(action)="row">
                    <b-button size="sm" variant="danger" @click="deleteBedHistory(row.item.id)">
                        刪除此筆記錄
                    </b-button>
                </template>
            </b-table>
        </div>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "CM-BedHumanProblem",
        methods:{
            filter(){
                this.modalShow = true;
                axios.get('/api/booking/get_wrong', {
                    params: {
                        serial: this.currentList
                    }
                })
                    .then((response)=>{
                        this.data = response.data;
                        this.modalShow = false;
                    })
            },
            deleteBedHistory(BedHistoryID){
                if(window.confirm("你確定要刪除它？")){
                    let bodyFormData = new FormData();
                    bodyFormData.append('id', "" + BedHistoryID);
                    bodyFormData.append("action", "4");
                    axios.post('/api/booking/BedHistoryItemBySerial',bodyFormData)
                        .then((response)=>{
                            window.alert(response.data.msg);
                            this.initial();
                        }).catch((error)=>{
                        window.alert(error.response.data.msg);
                        this.initial();
                    });
                }
            },
            initial(){
                axios.get('/api/booking/bed_history_by_serial')
                    .then((response) => {
                        this.lists_ori = response.data;
                        let x;
                        for(x in response.data){
                            this.lists.push({value: response.data[x].serial,  text: response.data[x].serial + " 建立於：" + response.data[x].time})
                        }
                    })
                    .catch((error) => {
                        window.console.log(error);
                    });
                axios.get('/api/booking/get_wrong', {
                    params: {
                        serial: this.currentList
                    }
                }).then((response)=>{this.data = response.data})
            }
        },
        mounted: function(){
            this.initial();
        },
        data: function () {
            return{
                lists: [{value: "", text: "全部"}],
                currentList: "",
                data: {},
                modalShow: false,
                fields: [
                    {
                        key: 'stuid',
                        sortable: true,
                        label: '學號'
                    },
                    {
                        key: 'name',
                        sortable: true,
                        label: '姓名'
                    },
                    {
                        key: 'room',
                        sortable: true,
                        label: '寢室'
                    },
                    {
                        key: 'serial',
                        sortable: true,
                        label: '名單'
                    },
                    {
                        key: 'status',
                        sortable: false,
                        label: '狀態'
                    },
                    {
                        key: 'action',
                        label: '處理'
                    }
                ],
            }
        }
    }
</script>

<style scoped>

</style>