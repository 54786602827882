<template>
    <div>
        <p>學號：<template v-for="(item, key) in userData.student_ids"><template v-if="key !== 0">、</template>{{item}}</template></p>
        <b-form-group
                id="name-title"
                description="此選項不建議更改，在學生登入時會與校務系統同步"
                label="姓名"
                label-for="input-name"
        >
            <b-form-input id="input-name" v-model="userData.name" trim></b-form-input>
        </b-form-group>
        <b-form-group
                id="unit-title"
                label="系級"
                label-for="input-unit"
        >
            <b-form-input id="input-unit" v-model="userData.unit" trim></b-form-input>
        </b-form-group>
        <b-form-group
                id="credential-title"
                label="證件號碼"
                label-for="input-credential"
        >
            <b-form-input id="input-credential" v-model="userData.credentials" trim></b-form-input>
        </b-form-group>
        <b-form-group
                label="使用者標籤"
                label-for="input-userTag"
        >
            <b-form-tags
                    input-id="input-userTag"
                    v-model="userData.userTags"
                    class="mb-2"
                    tag-remove-label="移除標籤"
                    tag-removed-label="標籤已移除"
                    placeholder="來貼點標籤吧"
            />
        </b-form-group>
        <b-form-group
                id="Phone-title"
                label="手機號碼"
                label-for="input-Phone"
        >
            <b-form-input id="input-Phone" type="tel" v-model="userData.phone" trim></b-form-input>
        </b-form-group>
        <b-form-group
                id="Email-title"
                label="電子郵件"
                label-for="input-Email"
        >
            <b-form-input id="input-Email" type="email" v-model="userData.email" trim></b-form-input>
            <b-form-checkbox name="check-button" v-model="userData.emailConfirm" switch>
                電子郵件驗證
            </b-form-checkbox>
        </b-form-group>
        <b-form-group
                id="E-Name-title"
                label="英文姓名"
                label-for="input-E-Name"
        >
            <b-form-input id="input-E-Name" v-model="userData.englishName"  trim></b-form-input>
        </b-form-group>
        <b-form-group
                id="enec-title"
                label="緊急聯絡人"
                label-for="input-enec"
        >
            <b-form-input id="input-enec" v-model="userData.EMEC" trim></b-form-input>
        </b-form-group>
        <b-form-group
                id="enecp-title"
                label="緊急聯絡人電話"
                label-for="input-enecp"
        >
            <b-form-input id="input-enecp" v-model="userData.EMECP" trim></b-form-input>
        </b-form-group>
        <b-form-group
                id="Remark-title"
                label="備註"
                label-for="input-Remark"
        >
            <b-form-textarea id="input-Remark" v-model="userData.remark"></b-form-textarea>
        </b-form-group>
        <b-form-group label="管理身份">
            <b-form-tags v-model="userData.policies" size="lg" add-on-change no-outer-focus class="mb-2">
                <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                    <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                        <li v-for="tag in tags" :key="tag" class="list-inline-item">
                            <b-form-tag
                                    @remove="removeTag(tag)"
                                    :title="tag"
                                    :disabled="disabled"
                                    variant="info"
                            >{{tag}}</b-form-tag>
                        </li>
                    </ul>
                    <b-form-select
                            v-bind="inputAttrs"
                            v-on="inputHandlers"
                            :disabled="disabled || availableOptions.length === 0"
                            :options="availableOptions"
                    >
                        <template v-slot:first>
                            <!-- This is required to prevent bugs with Safari -->
                            <option disabled value="">選擇</option>
                        </template>
                    </b-form-select>
                </template>
            </b-form-tags>
        </b-form-group>
        <!--<b-button variant="outline-primary">寄出電子郵件登入信</b-button>-->
        <b-button variant="outline-primary" block v-on:click="submitUpdate" v-bind:disabled="running">{{running? "處理中…": "保存所有更改"}}</b-button>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "UD-Info",
        data: function () {
            return {
                userData: {
                    name: '',
                    unit: '',
                    credentials: '',
                    policies: [],
                    phone: '',
                    email: '',
                    emailConfirm: false,
                    englishName: '',
                    EMEC: '',
                    EMECP: '',
                    remark: '',
                    userTags: [],
                    student_ids: []
                },
                allPolicy: [],
                running: false
            }
        },
        mounted: function () {
            this.f5();
            axios.get('/api/authority/all_policy').then((response)=>{this.allPolicy = response.data})
        },
        watch:{
            '$route' (to){
                axios.get('/api/user/detail',{
                    params: {
                        uuid: to.params.uuid
                    }
                }).then((response)=>{
                    this.userData = response.data;
                }).catch((error)=>{
                    if(error.response.status === 404){
                        window.alert('找不到該使用者，回到前一頁');
                        this.$router.push({name: 'userManagement'}).catch(()=>{});
                    }else if(error.response.status === 401) {
                        window.alert('你沒有使用這個功能的權限。');
                        this.$router.push({name: 'userManagement'}).catch(()=>{});
                    }else if(error.response.status === 403){
                        this.$store.dispatch('verifyStatus');
                    }else {
                        window.alert("系統出錯了QQ");
                        window.console.log(error.message);
                        this.$router.push({name: 'userManagement'}).catch(()=>{});
                    }
                })
            }
        },
        computed: {
            availableOptions() {
                return this.allPolicy.filter(opt => this.userData.policies.indexOf(opt) === -1)
            }
        },
        methods: {
            submitUpdate: function () {
                this.running = true;
                axios.post('/api/user/detail?uuid=' + this.$route.params.uuid, this.userData)
                .then(() =>{
                    this.running = false;
                })
                .catch((error)=>{
                    if(error.response.status === 400){
                        window.alert(error.response.data.msg);
                        this.f5();
                    }else if(error.response.status === 404){
                        window.alert('找不到該使用者，回到前一頁');
                        this.$router.push({name: 'userManagement'}).catch(()=>{});
                    }else if(error.response.status === 401) {
                        window.alert('你沒有使用這個功能的權限。');
                        this.$router.push({name: 'userManagement'}).catch(()=>{});
                    }else if(error.response.status === 403){
                        this.$store.dispatch('verifyStatus');
                    }else {
                        window.alert("系統出錯了QQ");
                        window.console.log(error.message);
                        this.$router.push({name: 'userManagement'}).catch(()=>{});
                    }
                    this.running = false;
                })
            },
            f5: function () {
                axios.get('/api/user/detail',{
                    params: {
                        uuid: this.$route.params.uuid
                    }
                }).then((response)=>{
                    this.userData = response.data;
                }).catch((error)=>{
                    if(error.response.status === 404){
                        window.alert('找不到該使用者，回到前一頁');
                        this.$router.push({name: 'userManagement'}).catch(()=>{});
                    }else if(error.response.status === 401) {
                        window.alert('你沒有使用這個功能的權限。');
                        this.$router.push({name: 'userManagement'}).catch(()=>{});
                    }else if(error.response.status === 403){
                        this.$store.dispatch('verifyStatus');
                    }else {
                        window.alert("系統出錯了QQ");
                        window.console.log(error.message);
                        this.$router.push({name: 'userManagement'}).catch(()=>{});
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>