<template>
    <div class="row justify-content-center">
        <div class="col-sm-5">
          <!-- 待刪 -->
          <!--<b-form-group
            id="payList"
            label="輸入繳費名單"
            lebel-for="bill"
          >
            <b-form-input
              type="text"
              placeholder="110學年度第一學期住宿費"
              v-model="upload.document_name"
              list="bill-options"
            >
            </b-form-input>
            <datalist id="bill-options">
              <option :key="bill"  v-for="bill in bills">{{bill}}</option>
            </datalist>
          </b-form-group>-->
          <br/>
            <b-form-group
                    id="fieldset-2"
                    label="匯入繳費清冊"
                    label-for="uploadFile-1"
            >
                <b-form-file
                        id="uploadFile-1"
                        v-model="upload.file"
                        :state="Boolean(upload.file)"
                        placeholder="選擇或把檔案拉過來"
                        drop-placeholder="把檔案拉到這裡～～"
                        accept=".xlsx"
                        browse-text="瀏覽"
                />
            </b-form-group>
            <small>可能會有點久，請耐心等候</small>
            <b-button block variant="primary" v-on:click="submit" v-bind:disabled="upload.sendBtnMsg === '資料處理中' || upload.file === null">{{upload.sendBtnMsg}}</b-button>
            <hr>
            <b-dropdown text="刪除繳費名單" @show="syncBills" block variant="danger" class="m-2" menu-class="w-100">
                <b-dropdown-item v-for="item in bills" v-bind:key="item" @click="delBill(item)">{{item}}</b-dropdown-item>
                <b-dropdown-item v-if="bills.length === 0">目前沒有資料～</b-dropdown-item>
            </b-dropdown>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "CM-Paied",
        data: function () {
            return{
                upload:{
                    file: null,
                    sendBtnMsg: '上傳',
                    document_name:""
                },
                bills: [
                    "108學年度暑期住宿費",
                    "108學年度第一學期住宿保證金",
                    "108學年度第二學期住宿費"
                ]

            }
        },
        methods:{
            submit: function () {
                this.upload.sendBtnMsg = '資料處理中';
                let formData = new FormData();
                formData.append('file', this.upload.file);
                axios.post(
                    '/api/misc/upload_bill',
                    formData,
                    {
                        headers:{
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then(()=>{
                    this.upload.sendBtnMsg = '上傳';
                }).catch((error)=>{
                    if(error.response.status === 400 || error.response.data.hasOwnProperty('msg')){
                        window.alert(error.response.data.msg);
                        this.upload.sendBtnMsg = '重新上傳';
                    }else{
                      window.console.log(error)
                        window.alert('不明的錯誤');
                        this.upload.sendBtnMsg = '重新上傳';
                    }
                })
            },
            syncBills: function () {
                this.bills = [];
                axios.get(
                    '/api/misc/upload_bill'
                ).then((response)=>{
                    this.bills = response.data
                    this.bills = this.bills.reverse()
                })
            },
            delBill: function (name) {
                if((window.confirm("我、本人、發誓沒有按錯，我要刪除『" + name + "』"))){
                    axios.delete('/api/misc/upload_bill',{
                        params: {
                            document_name: name
                        }
                    }).then((response)=>{
                        window.alert(response.data.msg)
                    })
                }
            }
        }
    }
</script>

<style scoped>

</style>
