<template>
  <b-table striped hover :fields="fields" :items="row_data">
    <template v-slot:cell(action)="row">
      <b-button size="sm" @click="row.toggleDetails">
        {{ row.detailsShowing ? '還是不要看好了' : '看看備註' }}
      </b-button>
    </template>
    <template v-slot:row-details="row">
      <b-card>
        <p>備註：<br><span class="text-wrapper">{{row.item.remark}}</span></p>
      </b-card>
    </template>
  </b-table>
</template>

<script>

export default {
  name: "UD-Borrow",
  data() {
    return {
      uid: "",
      row_data: [
      ],
      fields: [
        {
          key: 'thing',
          sortable: true,
          label: "借用物品"
        },
        {
          key: 'borrowTime',
          sortable: true,
          label: "借用時間"
        },
        {
          key: 'returnTime',
          sortable: true,
          label: "歸還時間"
        },
        {
          label: '備註',
          key: 'action'
        }]
    }
  }
}

</script>



<style scoped>

</style>