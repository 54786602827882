<template>
    <div>
        <b-modal
                v-model="modalShow"
                no-close-on-esc
                no-close-on-backdrop
                hide-header-close
                hide-footer
                hide-header
        >
            <p class="my-4">系統正在處理中</p>
        </b-modal>
        <b-modal id="ShowSomeThingMsg" hide-footer>
            <template v-slot:modal-title>
                您的要求已經被處理
            </template>
            <div class="d-block text-center">
                <p>處理結果：{{respMsg}}</p>
            </div>
            <b-button class="mt-3" block @click="$bvModal.hide('ShowSomeThingMsg')">關閉</b-button>
        </b-modal>
        <div class="row justify-content-center">
            <div class="col-sm-4">
                <b-card>
                    <h3>查詢可用床位</h3>
                    <hr>
                    <b-form-group
                            label-cols-sm="3"
                            label="條件："
                            label-align-sm="right"
                            description="可以找出沒人住且不在等待入住名單中的空房"
                    >
                        <b-input-group>
                            <b-form-select
                                    :options="['全部宿舍', '一宿', '二宿', '忠孝樓']"
                                    v-model="searchEmptyBed.query.dorm"
                            />
                            <b-form-select
                                    :options="['全部性別', '男', '女']"
                                    v-model="searchEmptyBed.query.sex"
                            />
                            <template v-slot:append>
                                <b-button v-on:click="getEmptyBed">搜尋</b-button>
                            </template>
                        </b-input-group>
                    </b-form-group>
                    <hr>
                    <div>
                        <b-container fluid>
                            <!-- User Interface controls -->
                            <b-row>
                                <b-col sm="5" md="6" class="my-1">
                                    <b-form-group
                                            label="每頁"
                                            label-cols-sm="6"
                                            label-cols-md="4"
                                            label-cols-lg="3"
                                            label-align-sm="right"
                                            label-size="sm"
                                            label-for="perPageSelect"
                                            class="mb-0"
                                    >
                                        <b-form-select
                                                v-model="searchEmptyBed.table.perPage"
                                                id="perPageSelect"
                                                size="sm"
                                                :options="[5, 10, 15, 20, 30, 50, 75, 100]"
                                        />
                                    </b-form-group>
                                </b-col>

                                <b-col sm="7" md="6" class="my-1">
                                    <b-pagination
                                            v-model="searchEmptyBed.table.currentPage"
                                            :total-rows="searchEmptyBed.table.data.length"
                                            :per-page="searchEmptyBed.table.perPage"
                                            align="fill"
                                            size="sm"
                                            class="my-0"
                                    />
                                </b-col>
                            </b-row>

                            <!-- Main table element -->
                            <b-table
                                    show-empty
                                    small
                                    stacked="md"
                                    :items="searchEmptyBed.table.data"
                                    :fields="searchEmptyBed.table.fields"
                                    :current-page="searchEmptyBed.table.currentPage"
                                    :per-page="searchEmptyBed.table.perPage"
                            >
                                <template v-slot:cell(id)="row">
                                    {{row.value}}
                                </template>
                                <template v-slot:cell(nameForHuman)="row">
                                    {{row.value}}
                                </template>

                                <template v-slot:cell(actions)="row">
                                    <b-button size="sm" class="mr-1" v-on:click="selectBed(row.item)">
                                        選擇
                                    </b-button>
                                </template>
                            </b-table>
                        </b-container>
                    </div>
                </b-card>
            </div>
            <div class="col">
                <b-card bg-variant="light">
                    <b-form-group
                            label-cols-sm="3"
                            label="住宿名單："
                            label-align-sm="right"
                            label-for="serial"
                    >
                        <b-form-input id="serial" list="serial-options" v-model="fieldsData.serial"/>
                        <datalist id="serial-options">
                            <option v-bind:key="serial" v-for="serial in metadata.serials">{{serial}}</option>
                        </datalist>
                    </b-form-group>

                    <b-form-group
                            label-cols-sm="3"
                            label="可入住日："
                            label-align-sm="right"
                            label-for="serial"
                    >
                        <b-form-input id="serial" type="date" v-model="fieldsData.nbf"/>
                    </b-form-group>
                    <hr>
                    <b-form-group
                            label-cols-sm="3"
                            label="學號"
                            label-align-sm="right"
                            label-for="StudentID"
                    >
                        <b-input-group>
                            <template v-slot:append>
                                <b-button v-on:click="matchUserByStuID">匹配學生資料庫（可選）</b-button>
                            </template>
                            <b-form-input id="StudentID" v-model="fieldsData.stuID" trim v-on:keyup.enter="matchUserByStuID"/>
                        </b-input-group>
                    </b-form-group>

                    <b-form-group
                            label-cols-sm="3"
                            label="證件編號："
                            label-align-sm="right"
                            label-for="credentials"
                    >
                        <b-form-input id="credentials" v-model="fieldsData.credentials" trim/>
                    </b-form-group>

                    <b-form-group
                            label-cols-sm="3"
                            label="姓名："
                            label-align-sm="right"
                            label-for="name"
                    >
                        <b-form-input id="name" v-model="fieldsData.name" trim/>
                    </b-form-group>

                    <b-form-group
                            label-cols-sm="3"
                            label="性別："
                            label-align-sm="right"
                            label-for="sex"
                    >
                        <b-form-radio-group
                                id="sex"
                                class="pt-2"
                                :options="['男', '女']"
                                v-model="fieldsData.sex"
                        />
                    </b-form-group>

                    <b-form-group
                            label-cols-sm="3"
                            label="系級單位："
                            label-align-sm="right"
                            label-for="unit"
                    >
                        <b-form-input id="unit" v-model="fieldsData.unit" trim/>
                    </b-form-group>
                    <small>注意：以學號為準，所有資料會根據上面給定的資料更新；但若是證件號碼已經由其他人佔用，則無法更新。</small>
                    <hr>
                    <b-form-group
                            label-cols-sm="3"
                            label="寢室："
                            label-align-sm="right"
                            class="mb-0"
                    >
                        <b-input-group>
                            <template v-slot:append>
                                <b-button v-on:click="matchRoom">搜尋</b-button>
                            </template>
                            <b-form-select
                                    :options="['一宿', '二宿', '忠孝樓']"
                                    v-model="fieldsData.room.dorm"
                                    :state="fieldsData.roomStatus.isFind"
                            />
                            <b-form-input trim placeholder="寢室" v-model="fieldsData.room.room" :state="fieldsData.roomStatus.isFind"/>
                            <b-form-input trim placeholder="床位" v-model="fieldsData.room.bed" :state="fieldsData.roomStatus.isFind"/>

                        </b-input-group>
                        <small v-show="fieldsData.roomStatus.isFind">已確認寢室『{{fieldsData.roomStatus.name}}』正確，ID：{{fieldsData.roomStatus.bedID}}</small>
                        <br>
                        <b-button block variant="primary" @click="add" v-bind:disabled="!!!fieldsData.roomStatus.isFind">送出資料</b-button>
                    </b-form-group>
                </b-card>
            </div>
        </div>

    </div>
</template>

<script>
    import axios from "axios";
    export default {
        name: "CM-Add",
        methods:{
            selectBed(item){
                this.fieldsData.room.room = item['room'];
                this.fieldsData.room.dorm = item['dorm'];
                this.fieldsData.room.bed = item['bed'];
                this.fieldsData.roomStatus.name = item['nameForHuman'];
                this.fieldsData.roomStatus.bedID = item['id'];
                this.fieldsData.roomStatus.isFind = true;
                this.matchRoom();
            },
            getEmptyBed(){
                this.modalShow = true;
                axios.get('/api/room/findEmptyBeds')
                .then((resp) => {
                    this.searchEmptyBed.result = resp.data;
                    this.searchEmptyBed.table.data = [];
                    let temp = [];
                    for (let x of this.searchEmptyBed.result){
                        if(this.searchEmptyBed.query.dorm === "全部宿舍"){
                            temp.push(x)
                        }else {
                            if(this.searchEmptyBed.query.dorm === x.dorm){
                                temp.push(x)
                            }
                        }
                    }
                    for (let x of temp){
                        if(this.searchEmptyBed.query.sex === "全部性別"){
                            this.searchEmptyBed.table.data.push(x)
                        }else {
                            if(this.searchEmptyBed.query.sex === x.sex){
                                this.searchEmptyBed.table.data.push(x)
                            }
                        }
                    }
                    this.modalShow = false;
                })
            },
            matchRoom(){
                this.modalShow = true;
                axios.get('/api/room/str2bed', {
                    params: {
                        dorm: this.fieldsData.room.dorm,
                        room: this.fieldsData.room.room,
                        bed: this.fieldsData.room.bed
                    }
                }).then((resp)=>{
                    this.fieldsData.roomStatus.bedID = resp.data.id;
                    this.fieldsData.roomStatus.name = resp.data.name;
                    this.fieldsData.roomStatus.isFind = true;
                    this.modalShow = false;
                }).catch((err) => {
                    if(err.response.status === 404){
                        this.fieldsData.roomStatus.bedID = "";
                        this.fieldsData.roomStatus.name = "";
                        this.fieldsData.roomStatus.isFind = false;
                        window.alert('找不到該床位');
                    }
                    this.modalShow = false;
                })
            },
            matchUserByStuID(){
                this.modalShow = true;
                axios.get('/api/user/searchForCheckin',{
                    params:{
                        stuID: this.fieldsData.stuID
                    }
                }).then((resp)=>{
                    this.fieldsData.credentials = resp.data.credentials;
                    this.fieldsData.sex = resp.data.sex;
                    this.fieldsData.unit = resp.data.unit;
                    this.fieldsData.name = resp.data.name;
                    this.modalShow = false;
                }).catch(()=>{
                    window.alert("找不到該用戶");
                    this.modalShow = false;
                })
            },
            add(){
                // 要做出另一個框框了…顯示回傳的訊息
                this.modalShow = true;
                let formData = new FormData();
                formData.append('bedId', this.fieldsData.roomStatus.bedID);
                formData.append('serial', this.fieldsData.serial);
                formData.append('nbf', this.fieldsData.nbf);
                formData.append('stuId', this.fieldsData.stuID);
                formData.append('name', this.fieldsData.name);
                formData.append('credentials', this.fieldsData.credentials);
                formData.append('sex', this.fieldsData.sex);
                formData.append('unit', this.fieldsData.unit);
                axios.post('/api/booking/add', formData).then(res=>{
                    this.respMsg = res.data.msg;
                    this.fieldsData = {
                        serial: "",
                        nbf: "",
                        stuID: "",
                        credentials: "",
                        sex: "",
                        unit: "",
                        name: "",
                        room:{
                            dorm: "",
                            room: "",
                            bed: ""
                        },
                        roomStatus:{
                            bedID: "",
                            name: "",
                            isFind: false
                        }
                    };
                    axios.get('/api/booking/only_get_bed_history_serial')
                        .then((resp)=>{
                            this.metadata.serials = resp.data;
                            this.modalShow = false;
                            this.$bvModal.show('ShowSomeThingMsg');
                        }).catch(()=>{
                          this.respMsg = "出現不明錯誤…";
                          this.modalShow = false;
                          this.$bvModal.show('ShowSomeThingMsg');
                    })
                })
            }
        },
        mounted: function(){
            this.modalShow = true;
            axios.get('/api/booking/only_get_bed_history_serial')
            .then((resp)=>{
                this.metadata.serials = resp.data;
                this.modalShow = false;
            }).catch((error)=>{
                if(error.response.status === 400){
                    window.alert(error.response.data.msg);
                    this.modalShow = false;
                }
            })
        },
        watch: {
            "fieldsData.room": {
                handler(){
                    this.fieldsData.roomStatus.bedID = "";
                    this.fieldsData.roomStatus.name = "";
                    this.fieldsData.roomStatus.isFind = false;
                },
                deep: true
            }
        },
        data: function () {
            return{
                modalShow: false,
                respMsg: '',
                searchEmptyBed:{
                    query:{
                        dorm: "全部宿舍",
                        sex: "全部性別"
                    },
                    table:{
                        fields: [
                            { key: 'id', label: 'ID'},
                            { key: 'nameForHuman', label: '床位'},
                            { key: 'actions', label: '選擇' }
                        ],
                        perPage: 10,
                        currentPage: 1,
                        data: []
                    },
                    result:[]
                },
                fieldsData:{
                    serial: "",
                    nbf: "",
                    stuID: "",
                    credentials: "",
                    sex: "",
                    unit: "",
                    name: "",
                    room:{
                        dorm: "",
                        room: "",
                        bed: ""
                    },
                    roomStatus:{
                        bedID: "",
                        name: "",
                        isFind: false
                    }
                },
                metadata:{
                    serials: []
                }
            }
        }
    }
</script>

<style scoped>

</style>