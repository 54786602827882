<template>
    <div>
        <b-row class="row-margin-top">
            <b-col sm="5" md="6">
                <b-form-group
                        label="每頁"
                        label-cols-sm="6"
                        label-cols-md="4"
                        label-cols-lg="3"
                        label-align-sm="right"
                        label-size="sm"
                        label-for="perPageSelect"
                        class="mb-0"
                >
                    <b-form-select
                            v-model="table.filter.pearPage"
                            size="sm"
                            :options="[10,20,30,40,50,100]"
                    ></b-form-select>
                </b-form-group>
            </b-col>
            <b-col sm="7" md="6">
                <b-pagination
                        v-model="table.filter.currentPage"
                        :total-rows="table.row_item.length"
                        :per-page="table.filter.pearPage"
                        align="fill"
                        size="sm"
                />
            </b-col>
        </b-row>
        <div class="w-100"/>
        <b-row>
            <b-table striped hover :items="table.row_item" :fields="table.fields" :current-page="table.filter.currentPage" :per-page="table.filter.pearPage">
                <template v-slot:cell(action)="row">
                    <b-button size="sm" @click="row.toggleDetails">
                        {{ row.detailsShowing ? '還是不要看好了' : '看看當初的理由' }}
                    </b-button>
                </template>
                <template v-slot:cell(name)="row">
                    <router-link :to="{name: 'userManagementDetails', params: {uuid: row.item.uuid }}">{{row.value}}</router-link>
                </template>
                <template v-slot:row-details="row">
                    <b-card>
                        <p>理由：<br>{{row.item.reason}}</p>
                    </b-card>
                </template>
            </b-table>
        </b-row>
    </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "UD-CurfewExemptRecord",
        data: function () {
            return {
                upload_file: null,
                table: {
                    row_item: [],
                    filter: {
                        pearPage: 10,
                        currentPage: 1
                    },
                    fields: [
                        {
                            label: '申請時間',
                            key: 'apply_time',
                            sortable: true
                        },
                        {
                            label: '豁免時間',
                            key: 'exempt_day',
                            sortable: true
                        },
                        {
                            label: '申請人',
                            key: 'name',
                        },
                        {
                            label: '狀態',
                            key: 'status',
                        },
                        {
                            label: '理由',
                            key: 'action'
                        }
                    ]
                }
            }
        },
        methods: {
            getList: function(){
                let uuid = this.$route.params.uuid;
                axios.get("/api/curfew_exempt/CurfewExemptApplyCheck?uuid=" + uuid
                ).then((response)=>{
                    this.table.row_item = response.data;
                })
            }
        },
        mounted: function () {
            this.getList();
        },
    }
</script>

<style scoped>

</style>