<template>
    <div>
        <div class="row justify-content-center">
            <div class="col">
                <b-input-group class="mt-3">
                    <b-input-group-prepend>
                        <b-form-select
                                :options="search.primaryOptions"
                                v-model="search.primaryType"
                                v-bind:disabled="search.loading"
                        />
                    </b-input-group-prepend>
                    <b-form-input v-model="search.primaryWord" v-bind:disabled="search.loading" v-on:keypress.enter="query"/>
                    <b-input-group-append>
                        <b-button v-bind:disabled="search.loading" v-on:click="query">{{search.loading? "搜尋中…": "搜尋"}}</b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                        <b-button v-on:click="search.moreOption = !search.moreOption" variant="outline-secondary" v-bind:disabled="search.loading">{{query.moreOption? '隱藏': "顯示"}}更多搜尋選項</b-button>
                    </b-input-group-append>
                </b-input-group>
                <b-collapse id="collapse-fuzzy-search" class="mt-2" v-model="search.moreOption">
                    <b-row>
                        <b-col class="col-12 col-sm-12 col-md-4 col-lg-3 col-xl-3">
                            <b-card>
                                <b-form-checkbox
                                    v-model="search.moreOptions.onlyShowNow"
                                    value="1"
                                    unchecked-value="0"
                                >
                                    只搜尋現有住宿生
                                </b-form-checkbox>
                                <b-form-checkbox
                                    v-model="search.moreOptions.show50up"
                                    value="1"
                                    unchecked-value="0"
                                >
                                    顯示大於50筆資料
                                </b-form-checkbox>
                            </b-card>
                        </b-col>
                        <b-col class="col-12 col-sm-12 col-md-8 col-lg-9 col-xl-9">
                            <b-form-group label="選擇標籤以搜尋">
                                <b-form-tags v-model="search.tagFilter.value" size="lg" add-on-change no-outer-focus class="mb-2">
                                    <template v-slot="{ tags, inputAttrs, inputHandlers, disabled, removeTag }">
                                        <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                                            <li v-for="tag in tags" :key="tag" class="list-inline-item">
                                                <b-form-tag
                                                    @remove="removeTag(tag)"
                                                    :title="tag"
                                                    :disabled="disabled"
                                                    variant="info"
                                                >{{ tag }}</b-form-tag>
                                            </li>
                                        </ul>
                                        <b-form-select
                                            v-bind="inputAttrs"
                                            v-on="inputHandlers"
                                            :disabled="disabled || availableOptions.length === 0"
                                            :options="availableOptions"
                                        >
                                            <template #first>
                                                <!-- This is required to prevent bugs with Safari -->
                                                <option disabled value="">來這裡挑選標籤</option>
                                            </template>
                                        </b-form-select>
                                    </template>
                                </b-form-tags>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-collapse>
                <hr>
            </div>
            <div class="w-100"/>
            <div class="col" v-if="table.items.length !== 0">
                <b-button block variant="primary" v-on:click="table.show = !table.show">{{table.show? '隱藏':'顯示'}}搜尋結果列表</b-button>
                <b-collapse id="collapse-table" class="mt-2" v-model="table.show">
                    <b-card>
                        <b-table responsive striped hover :items="table.items" :fields="table.fields">
                            <template v-slot:cell(room)="row">
                                <template v-if="row.item.bh_info !== null">
                                    <router-link :to="{name: 'roomManagementDetails', params: { room_id: row.item.bh_info['id']}}">{{row.item.bh_info.name4human}}</router-link>
                                </template>
                                <template v-else>無</template>
                            </template>
                            <template v-slot:cell(select)="row">
                                <b-button v-on:click="select(row.item)">選取</b-button>
                            </template>
                        </b-table>
                    </b-card>
                </b-collapse>
            </div>
        </div>
        <router-view/>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "userManagement",
        methods:{
            syncTags: function (){
                axios.get("/api/tag/user_tags")
                    .then((response)=>{
                        response.data.forEach(item => this.search.tagFilter.options.push(item.name));
                    })
            },
            query: function () {
                let qs = require('qs');
                this.$router.push({name: 'userManagement'}).catch(()=>{});
                this.search.loading = true;
                this.table.show = false;
                axios.get('/api/user/search', {
                    params: {
                        primaryType: this.search.primaryType,
                        primaryWord: this.search.primaryWord,
                        onlyShowNow: this.search.moreOptions.onlyShowNow,
                        show50up: this.search.moreOptions.show50up,
                        tags: this.search.tagFilter.value
                    },
                    paramsSerializer: {
                        serialize: qs.stringify
                    }
                }).then((response)=>{
                    this.table.items = response.data;
                    this.search.loading = false;
                    this.table.show = true;
                }).catch((err)=>{
                    this.search.loading = false;
                    if(err.response.status === 403){
                        this.$store.dispatch('verifyStatus');
                    }else if(err.response.status === 401){
                        window.alert('你沒有足夠的權限存取這個功能。');
                    }else {
                        window.alert('發生錯誤，請嘗試重新登入');
                    }
                })
            },
            select: function (item) {
                this.table.show = false;
                this.$router.push({name: 'userManagementDetails', params: { uuid: item.uuid }}).catch(()=>{});
            }
        },
        data: function () {
            return{
                search: {
                    primaryType: '學號',
                    primaryWord: '',
                    primaryOptions: [
                        '學號',
                        '姓名',
                        '證件號碼',
                        '電話號碼',
                        '電子郵件'
                    ],
                    moreOption: false,
                    loading: false,
                    moreOptions:{
                        onlyShowNow: false,
                        show50up: false
                    },
                    tagFilter: {
                        options: [],
                        value: []
                    }
                },
                table:{
                    show: true,
                    fields: [
                        {label: '選取', key: 'select'},
                        {label: '寢室', key: 'room'},
                        {label: '學號', key: 'stuId'},
                        {label: '姓名', key: 'name'},
                        {label: '系級', key: 'unit'},
                        {label: '電話', key: 'phone'}
                    ],
                    items: []
                }
            }
        },
        computed: {
            availableOptions() {
                return this.search.tagFilter.options.filter(opt => this.search.tagFilter.value.indexOf(opt) === -1)
            }
        },
        mounted: function () {
            this.syncTags();
        }
    }
</script>

<style scoped>

</style>