<template>
    <div>
        <div class="row justify-content-center">
            <div class="col-sm">
                <b-input-group class="mt-3">
                    <b-input-group-prepend>
                        <b-form-select
                                :options="['全部', '一宿', '二宿' ,'忠孝樓']"
                                v-model="search_data.dormType"
                        />
                    </b-input-group-prepend>
                    <b-form-input @keypress.enter="submit" v-model="search_data.primaryWord"/>
                    <b-input-group-append>
                        <b-button @click="submit">搜尋</b-button>
                    </b-input-group-append>
                    <b-input-group-append>
                        <b-button variant="outline-secondary" v-on:click="search_data.moreOption = !search_data.moreOption">{{search_data.moreOption? '隱藏': "顯示"}}更多搜尋選項</b-button>
                    </b-input-group-append>
                </b-input-group>
                <b-collapse id="collapse-fuzzy-search" class="mt-2" v-model="search_data.moreOption">
                    <b-card>
                        <p class="card-text">模糊查詢，但我還不知道行政人員會需要哪些查詢</p>
                    </b-card>
                </b-collapse>
                <hr>
            </div>
            <div class="w-100"/>
            <div class="col-sm" v-if="table.items.length !== 0">
                <b-button block variant="primary" v-on:click="table.show = !table.show">{{table.show? '隱藏':'顯示'}}搜尋結果列表</b-button>
                <b-collapse id="collapse-table" class="mt-2" v-model="table.show">
                    <b-card>
                        <b-table responsive striped hover :items="table.items" :fields="table.fields">
                            <template v-slot:cell(select)="row">
                                <b-button @click="goSelect(row.item.id)">選取</b-button>
                            </template>
                            <template v-slot:cell(avaBed)="row">
                                {{row.item.avaBed.length}}
                            </template>
                        </b-table>
                    </b-card>
                </b-collapse>
            </div>
        </div>
        <router-view/>
    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "RoomManagement",
        data: function () {
            return {
                search_data: {
                    moreOption: false,
                    dormType: '全部',
                    primaryWord: ''
                },
                table: {
                    items: [],
                    fields: [
                        {label: '選取', key: 'select'},
                        {label: '宿舍', key: 'dorm'},
                        {label: '房號', key: 'name', sortable: true},
                        {label: '剩餘床位', key: 'avaBed', sortable: true},
                        {label: '可用', key: 'available'}
                    ],
                    show: false
                }
            }
        },
        methods: {
            submit: function () {
                axios.post('/api/room/search', {
                    "dormType": this.search_data.dormType,
                    "primaryWord": this.search_data.primaryWord
                }).then((response)=>{
                    this.table.items = response.data.rooms;
                    this.table.show = true;
                })
            },
            goSelect: function (roomId) {
                this.table.show = false;
                this.$router.push({name: 'roomManagementDetails', params: { room_id: roomId }}).catch(()=>{});
            }
        }
    }
</script>

<style scoped>

</style>