<template>
    <b-card
            title="學生管理頁面"
    >
        <div class="row justify-content-center">
            <div class="col-sm-2">
                <div>
                    <b-list-group>
                        <b-list-group-item :to="{name: 'UD-Info'}" v-bind:active="$route.name === 'UD-Info'">基本資料</b-list-group-item>
                        <b-list-group-item disabled>包裹記錄</b-list-group-item>
                        <b-list-group-item disabled>晚歸記錄</b-list-group-item>
                        <b-list-group-item :to="{name: 'UD-History'}">住宿記錄</b-list-group-item>
                        <b-list-group-item :to="{name: 'UD-Borrow'}">物品借用</b-list-group-item>
                        <b-list-group-item :to="{name: 'UD-PayLodgingMatch'}">帳務與住宿簡歷</b-list-group-item>
                    </b-list-group>
                </div>
            </div>
            <div class="col">
                <router-view/>
            </div>
        </div>
    </b-card>
</template>

<script>
    export default {
        name: "UD-Home"
    }
</script>

<style scoped>

</style>