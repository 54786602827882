<template>
    <div class="row justify-content-center">
        <div class="col-sm-5">
            <b-form-group
                    id="fieldset-0"
                    label="為這次匯入取個名字："
                    label-for="input-1"
                    description="像是1081延長住宿或是1082開學報到，留白預設為時間；可針對同一名單以匯入的形式添加住宿生。"
            >
                <b-form-input
                        type="text"
                        max="16"
                        v-model="upload.name"
                        placeholder="為這次上傳取個好名字EX：1091入住名單"
                        list="serial-options"
                />
                <datalist id="serial-options">
                    <option v-bind:key="serial" v-for="serial in serials">{{serial}}</option>
                </datalist>
            </b-form-group>
            <b-form-group
                    id="fieldset-1"
                    label="本名單生效於："
                    label-for="input-1"
                    description="這批人要到什麼時候可以報到"
            >
                <b-form-input
                        type="date"
                        max="16"
                        v-model="upload.time"
                />
            </b-form-group>
            <b-form-group
                    id="fieldset-2"
                    description="格式在最下方有說明"
                    label="匯入住宿名單"
                    label-for="uploadFile-1"
            >
                <b-form-file
                        id="uploadFile-1"
                        v-model="upload.file"
                        :state="Boolean(upload.file)"
                        placeholder="選擇或把檔案拉過來"
                        drop-placeholder="把檔案拉到這裡～～"
                        accept=".xlsx"
                        browse-text="瀏覽"
                />
            </b-form-group>
            <small>可能會有點久，請耐心等候</small>
            <b-button block variant="primary" v-on:click="submit" v-bind:disabled="sendBtnMsg === '資料處理中'">{{sendBtnMsg}}</b-button>
        </div>
        <div class="col">
            <div v-if="result.status !== ''">
                <h3>匯入結果</h3>
                <h5>{{showMsgForResult}}</h5>
                <div v-show="result.msg === '操作成功'">
                    <div role="tablist">
                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-1 variant="info">成功匯入：{{result.success.length}} 人</b-button>
                            </b-card-header>
                            <b-collapse id="accordion-1" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-card-text>{{ result.success }}</b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>

                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-2 variant="info">查無床位：{{result.bed404.length}} 人</b-button>
                            </b-card-header>
                            <b-collapse id="accordion-2" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-card-text>{{ result.bed404 }}</b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>

                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-3 variant="info">兩人共枕：{{result.bed2people.length}} 人</b-button>
                            </b-card-header>
                            <b-collapse id="accordion-3" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-card-text>{{ result.bed2people }}</b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>

                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-4 variant="info">建立了{{result.create.length}}位新住宿生</b-button>
                            </b-card-header>
                            <b-collapse id="accordion-4" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-card-text>{{ result.create }}</b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>

                        <b-card no-body class="mb-1">
                            <b-card-header header-tag="header" class="p-1" role="tab">
                                <b-button block href="#" v-b-toggle.accordion-5 variant="info">更新了{{result.update.length}}位學生學號資料</b-button>
                            </b-card-header>
                            <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
                                <b-card-body>
                                    <b-card-text>{{ result.update }}</b-card-text>
                                </b-card-body>
                            </b-collapse>
                        </b-card>
                    </div>
                </div>
            </div>
            <div v-else>
                <h3>說明</h3>
                <p>
                    上傳檔案需要是Excel的xlsx檔案，目前確定Excle 2019可以正常讀取，理論上2013後即可 <br>
                    檔案格式如下，注意第一列的說明需要刪除，若沒有刪除，是也不會發生什麼啦… <br>
                    <code>一宿	B201	A	應數四	10545847	謝大偉	男	F999999999</code> <br>
                    請確保資料正確，這項操作會在建立床位預約的同時為不在系統內的同學建立帳戶 <br>
                    若是床位有問題，可以撤銷，但是如果帳戶建立錯誤（主要是姓名、身份證字號、學號），是沒有辦法撤銷的… <br>
                    系統可以接受沒有學號但身份證字號可在系統內查到，系統會自動填上學號；沒有身份證字號系統會配發一組隨機的號碼 <br>
                    可以以同一個『名字』增加入住名單，系統會檢查床位有沒有重複
                </p>
            </div>

        </div>

    </div>
</template>

<script>
    import axios from 'axios'
    export default {
        name: "CM-Upload",
        data: function () {
            return{
                upload:{
                    file: null,
                    name: "",
                    time: ""
                },
                result:{
                    status: "",
                    success: 0,
                    bed404: 0,
                    bed2people: 0,
                    serial: "",
                    msg: "",
                    create: 0,
                    update: 0,
                    err: false
                },
                sendBtnMsg: "送出資料",
                serials: []
            }
        },
        computed: {
            showMsgForResult() {
                let temp = "";
                switch (this.result.msg) {
                    case "":
                        temp = "尚未上傳資料";
                        break;
                    case "操作成功":
                        temp = "本次上傳完成，上傳批號：" + this.result.serial;
                        break;
                    default:
                        temp = "上傳出現錯誤，訊息：" + this.result.msg;
                }
                return temp
            }
        },
        mounted: function(){
          this.modalShow = true;
          axios.get('/api/booking/only_get_bed_history_serial')
              .then((resp)=>{
                this.serials = resp.data;
              }).catch((error)=>{
            if(error.response.status === 400){
              window.alert(error.response.data.msg);
            }
          })
        },
        methods:{
            submit: function () {
                let formData = new FormData();
                formData.append('file', this.upload.file);
                formData.append('name', this.upload.name);
                formData.append('after', this.upload.time);
                this.sendBtnMsg = "資料處理中";
                axios.post(
                    '/api/booking/upload',
                    formData,
                    {
                        headers:{
                            'Content-Type': 'multipart/form-data'
                        }
                    }
                ).then((response)=>{
                    this.result.status = "上傳成功";
                    this.result.msg = response.data.msg;
                    this.result.success = response.data.success;
                    this.result.bed404 = response.data.bed404;
                    this.result.bed2people = response.data.one_bed2people;
                    this.result.update = response.data.user_updated;
                    this.result.create = response.data.user_created;
                    this.result.serial = response.data.serial;
                    this.result.err = false;
                    this.sendBtnMsg = "送出資料";
                }).catch((error)=>{
                    this.result.status = "上傳沒有成功";
                    this.sendBtnMsg = "送出資料";
                    this.result.err = true;
                    this.result.msg = error.message;
                    this.result.success = 0;
                    this.result.bed404 = 0;
                    this.result.bed2people = 0;
                    this.result.update = 0;
                    this.result.create = 0;
                    this.result.serial = "";
                    if (error.response.status === 400){
                        this.result.msg = error.response.data.msg;
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>