<template>
    <div class="row justify-content-center">
        <b-modal
                v-model="modalShow"
                no-close-on-esc
                no-close-on-backdrop
                hide-header-close
                v-bind:hide-footer="currentPanel.runningStatus === 3"
                hide-header
        >
            <p class="my-4" v-if="currentPanel.runningStatus === 1">系統正在處理中</p>
            <p class="my-4" v-if="currentPanel.runningStatus === 2">完成，執行結果：<br>
                {{currentPanel.runningMsg}}</p>
            <template v-slot:modal-footer>
                <b-button block v-on:click="resetRunning" v-show="currentPanel.runningStatus === 2">關閉</b-button>
            </template>
        </b-modal>
        <b-modal ref="change-bed-modal" @hidden="changeBedClose" hide-footer title="更改床位">
            <div class="d-block text-center">
                <h3>更改床位</h3>
                <p>僅單純移動床位而已</p>
            </div>
            <lock-room @locked="changeBedLocked" v-on:unlock="changeBedUnlock"/>
            <b-button class="mt-3" variant="primary" block v-bind:disabled="!changeBed.isStandBy" @click="doChangeAction">換！</b-button>
        </b-modal>
        <div class="col-sm-3">
            <b-pagination
                v-model="leftList.currentPage"
                :total-rows="leftList.history.length"
                :per-page="10"
                align="fill"
                size="sm"
                class="my-0"
            />
            <b-table :tbody-tr-class="rowClass" :items="leftList.history" :fields="leftList.fields" responsive="sm" :per-page="10" :current-page="leftList.currentPage">
                <template #cell(show_details)="row">
                    <b-button size="sm" @click="row.toggleDetails" class="mr-2">
                        {{ row.detailsShowing ? '縮起來' : '看看詳細資料'}}
                    </b-button>
                    <b-button :disabled="row.item.serial === currentPanel.serial" size="sm" class="mr-2" v-on:click="currentPanel.serial = row.item['serial']">
                        選擇
                    </b-button>
                </template>
                <template #row-details="row">
                    <p class="mb-1">
                        一宿 二宿 忠孝樓<br>
                       （已入住/未入住/已離宿/已取消）： <br>
                        一宿：{{row.item['1mashita']}} / {{row.item['1mada']}} / {{row.item['1_exit']}} / {{row.item['1_cancel']}};<br>
                        二宿：{{row.item['2mashita']}} / {{row.item['2mada']}} / {{row.item['2_exit']}} / {{row.item['2_cancel']}};<br>
                        忠孝樓：{{row.item['zmashita']}} / {{row.item['zmada']}} / {{row.item['z_exit']}} / {{row.item['z_cancel']}};
                    </p>
                    <small>此名單最後修改於：{{row.item['time']}}</small>
                </template>
            </b-table>
        </div>
        <div class="col" v-show="rightResults.results.originData.length !== 0">
            <div>
                <b-form-group
                        id="fieldset-1"
                        label="在此住宿名單中搜尋（不需查詢的因素留白即可）"
                        label-for="input-1"
                >
                    <b-input-group class="mb-2">
                        <b-input-group-prepend>
                            <b-form-select
                                    :options="rightResults.search.option"
                                    v-model="rightResults.search.current"
                            />
                        </b-input-group-prepend>
                        <b-form-input
                                type="text"
                                max="16"
                                v-model="rightResults.search.keyword"
                                placeholder="範例：10511299"
                                @keypress.enter="filter"
                        />
                        <b-input-group-prepend is-text>
                            篩選：
                        </b-input-group-prepend>
                        <b-input-group-prepend>
                            <b-form-select
                                v-model="rightResults.search.filterSelect"
                                :options="['全部狀態', '已入住', '已離宿', '已取消', '未入住', '已換房']"
                            />
                        </b-input-group-prepend>
                        <b-input-group-prepend is-text>
                            宿舍：
                        </b-input-group-prepend>
                        <b-input-group-prepend>
                            <b-form-select
                                    :options="rightResults.search.dormOption"
                                    v-model="rightResults.search.dormCurrent"
                            />
                        </b-input-group-prepend>
                        <b-form-input
                                type="text"
                                max="16"
                                v-model="rightResults.search.room"
                                placeholder="範例：A530"
                                @keypress.enter="filter"
                        />
                        <b-input-group-append><b-button v-on:click="filter" variant="primary">搜尋</b-button><b-button v-on:click="syncSerialContext(currentPanel.serial)"><b-icon-arrow-repeat/></b-button></b-input-group-append>
                    </b-input-group>
                </b-form-group>
            </div>
            <div>
                <b-container fluid>
                    <!-- User Interface controls -->
                    <b-row>
                        <b-col sm="5" md="6" class="my-1">
                            <b-form-group
                                    label="每一頁"
                                    label-cols-sm="6"
                                    label-cols-md="4"
                                    label-cols-lg="3"
                                    label-align-sm="right"
                                    label-size="sm"
                                    label-for="perPageSelect"
                                    class="mb-0"
                            >
                                <b-form-select
                                        v-model="rightResults.results.perPage"
                                        id="perPageSelect"
                                        size="sm"
                                        :options="[5, 10, 15, 20, 30, 50, 75, 100]"
                                />
                            </b-form-group>
                        </b-col>

                        <b-col sm="7" md="6" class="my-1">
                            <b-pagination
                                    v-model="rightResults.results.currentPage"
                                    :total-rows="rightResults.results.lists.length"
                                    :per-page="rightResults.results.perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                            />
                        </b-col>
                    </b-row>

                    <!-- Main table element -->
                    <b-table
                            show-empty
                            small
                            stacked="md"
                            :items="rightResults.results.lists"
                            :fields="rightResults.results.fields"
                            :current-page="rightResults.results.currentPage"
                            :per-page="rightResults.results.perPage"
                            :sort-compare="sortCompare"
                    >
                        <template v-slot:cell(id)="row">
                            {{row.value}}
                        </template>
                        <template v-slot:cell(stuId)="row">
                            {{row.value}}
                        </template>
                        <template v-slot:cell(name)="row">
                            <router-link :to="{name: 'userManagementDetails', params: {uuid: row.item.uuid }}">{{row.value}}</router-link>
                        </template>
                        <template v-slot:cell(bed)="row">
                            {{row.item.dorm}}{{row.item.room}}-{{row.item.bed}}
                        </template>
                        <template v-slot:cell(status)="row">
                            {{row.value}}
                        </template>

                        <template v-slot:cell(actions)="row">
                            <b-button size="sm" v-if="row.item.status === '未入住'" @click="actionOfItemBtn(row.item, 1)" class="mr-1">
                                入住
                            </b-button>
                            <b-button size="sm" v-if="row.item.status === '未入住'"  @click="actionOfClickModifyBed(row.item)" class="mr-1">
                                修改床位
                            </b-button>
                            <b-button size="sm" v-if="row.item.status === '已入住'" @click="actionOfItemBtn(row.item, 2)" class="mr-1">
                                取消入住
                            </b-button>
                            <b-button size="sm" v-if="row.item.status === '已入住'" @click="actionOfItemBtn(row.item, 3)" class="mr-1">
                                退宿
                            </b-button>
                            <b-button variant="danger" size="sm" @click="actionOfItemBtn(row.item, 4)" class="mr-1">
                                刪除資料
                            </b-button>
                            <b-button size="sm" v-if="row.item.status === '未入住'" @click="actionOfItemBtn(row.item, 5)" class="mr-1">
                                放棄住宿
                            </b-button>
                        </template>
                    </b-table>
                </b-container>
            </div>
            <div>
                <small>刪除：刪除會連曾經入住過都查不到；退房：該床位會從佔用狀態被清空，但是可以查到此人曾經住過這裡；取消入住：從已報到改為等待報到。</small>
            </div>
            <hr/>
            <b-input-group prepend="執行操作：" class="mt-3">
                <b-form-select
                        :options="rightResults.listAction.actions"
                        v-model="rightResults.listAction.current"
                />
                <b-input-group-append><b-button @click="actionOfList">執行</b-button></b-input-group-append>
            </b-input-group>
        </div>
    </div>
</template>

<script>
    import axios from "axios";
    import lockRoom from "@/components/lockRoom";

    export default {
        name: "CM-Manager",
        mounted: function(){
            axios.get('/api/booking/bed_history_by_serial')
                .then((response) => {
                    this.leftList.history = response.data;
                })
                .catch((error) => {
                    window.console.log(error);
                });
        },
        computed: {
            modalShow: function () {
                return this.currentPanel.runningStatus !== 0
            }
        },
        watch:{
          'currentPanel.serial'(newValue){
              this.syncSerialContext(newValue)
          }
        },
        methods: {
            syncSerialContext(serial){
                this.currentPanel.runningStatus = 1;
                this.rightResults.results.originData = [];
                this.rightResults.search.current = "學號";
                this.rightResults.search.keyword= "";
                this.rightResults.search.dormCurrent = "全部";
                this.rightResults.search.room = "";
                this.rightResults.search.filterSelect = '全部狀態';
                this.rightResults.results.currentPage = 1;
                this.rightResults.results.lists = [];
                this.rightResults.results.originData = [];
                axios.get(
                    '/api/booking/BedHistoryItemBySerial',
                    {
                        params: {
                            serial: serial
                        }
                    }
                ).then((response)=>{
                    this.rightResults.results.originData = response.data;
                    this.rightResults.results.lists = this.rightResults.results.originData;
                    this.currentPanel.runningStatus = 0;
                })
            },
            actionOfList(){
                this.currentPanel.runningStatus = 1;
                let bodyFormData = new FormData();
                bodyFormData.append('serial', this.currentPanel.serial);
                bodyFormData.append('action', this.rightResults.listAction.current);
                axios.post(
                    '/api/booking/bed_history_by_serial',
                    bodyFormData
                ).then((res)=>{
                    axios.get(
                        '/api/booking/BedHistoryItemBySerial',
                        {
                            params: {
                                serial: this.currentPanel.serial
                            }
                        }
                    ).then((response)=>{
                        this.rightResults.results.originData = response.data;
                        this.rightResults.results.lists = this.rightResults.results.originData;
                        this.filter();
                        axios.get('/api/booking/bed_history_by_serial')
                            .then((response) => {
                                this.leftList.history = response.data;
                                this.currentPanel.runningStatus = 2;
                                let tempList = [];
                                this.leftList.history.forEach((item)=>{
                                   tempList.push(item.serial);
                                });
                                if(this.currentPanel.serial in tempList){
                                    this.syncSerialContext(this.currentPanel.serial);
                                }else {
                                    this.currentPanel.serial = "";
                                }
                            })
                            .catch((error) => {
                                window.console.log(error);
                            });
                    });
                    this.currentPanel.runningMsg = res.data.msg;
                })
            },
            actionOfItemBtn(item, actionID) {
                // 要做什麼
                this.currentPanel.runningStatus = 1;
                let bodyFormData = new FormData();
                bodyFormData.append('id', "" + item['id']);
                bodyFormData.append("action", "" + actionID);
                axios.post('/api/booking/BedHistoryItemBySerial',bodyFormData)
                    .then((res)=>{
                        axios.get(
                            '/api/booking/BedHistoryItemBySerial',
                            {
                                params: {
                                    serial: this.currentPanel.serial
                                }
                            }
                        ).then((response)=>{
                            this.rightResults.results.originData = response.data;
                            this.rightResults.results.lists = this.rightResults.results.originData;
                            this.filter();
                            axios.get('/api/booking/bed_history_by_serial')
                                .then((response) => {
                                    this.leftList.history = response.data;
                                    this.currentPanel.runningStatus = 2;
                                })
                                .catch((error) => {
                                    window.console.log(error);
                                });
                        });
                    this.currentPanel.runningMsg = res.data.msg;
                })
            },
            filter(){
                let temp1 = [];
                let temp2 = [];
                temp1 = this.rightResults.results.originData;
                if(this.rightResults.search.keyword !== ""){
                    for(let item of temp1){
                        if(this.rightResults.search.current === "學號" && item['stuId'] === this.rightResults.search.keyword){
                            temp2.push(item)
                        }else if (this.rightResults.search.current === "證件號碼" && item['credentials'] === this.rightResults.search.keyword){
                            temp2.push(item)
                        }
                    }
                    temp1 = temp2;
                }
                temp2 = [];
                for(let item of temp1){
                    if(this.rightResults.search.dormCurrent === '全部'){
                        temp2.push(item)
                    }else if(this.rightResults.search.dormCurrent === item['dorm']){
                        temp2.push(item)
                    }
                }
                temp1 = temp2;
                temp2 = [];
                for(let item of temp1){
                    if(this.rightResults.search.room === ""){
                        temp2.push(item)
                    }else if(item['room'] === this.rightResults.search.room){
                        temp2.push(item)
                    }
                }
                temp1 = temp2; // 目前被篩到剩下的
                temp2 = []; // 即將顯示的
                for(let item of temp1){
                    switch (this.rightResults.search.filterSelect){
                        case "全部狀態":
                            temp2.push(item);
                            break;
                        case "已入住":
                            if(item['status'] === '已入住'){
                                temp2.push(item);
                            }
                            break;
                        case "已離宿":
                            if(item['status'] === '已離宿'){
                                temp2.push(item);
                            }
                            break;
                        case "已取消":
                            if(item['status'] === '已取消'){
                                temp2.push(item);
                            }
                            break;
                        case '未入住':
                            if(item['status'] === '未入住'){
                                temp2.push(item);
                            }
                            break;
                        case '已換房':
                            if(item['status'] === '已換房'){
                                temp2.push(item);
                            }
                    }
                }
                this.rightResults.results.lists = temp2;
            },
            resetRunning(){
                this.currentPanel.runningStatus = 0;
                this.currentPanel.runningMsg = '';
            },
            actionOfClickModifyBed(item){
                this.changeBed.oriSerial = item['id'];
                this.changeBed.show = true;
                this.$refs['change-bed-modal'].show();
            },
            changeBedClose(){
                this.changeBed = {
                    oriSerial: '',
                    newBed: '',
                    show: false,
                    isStandBy: false
                }
            },
            changeBedLocked(item){
                this.changeBed.newBed = item['id'];
                this.changeBed.isStandBy = true;
            },
            changeBedUnlock(){
                this.changeBed.newBed = '';
                this.changeBed.isStandBy = false;
            },
            doChangeAction(){
                let bodyFormData = new FormData();
                bodyFormData.append('id', "" + this.changeBed.oriSerial);
                bodyFormData.append("action", "6");
                bodyFormData.append("target", "" + this.changeBed.newBed);
                axios.post('/api/booking/BedHistoryItemBySerial',bodyFormData)
                    .then((res)=>{
                        axios.get(
                            '/api/booking/BedHistoryItemBySerial',
                            {
                                params: {
                                    serial: this.currentPanel.serial
                                }
                            }
                        ).then((response)=>{
                            this.rightResults.results.originData = response.data;
                            this.rightResults.results.lists = this.rightResults.results.originData;
                            this.filter();
                            axios.get('/api/booking/bed_history_by_serial')
                                .then((response) => {
                                    this.leftList.history = response.data;
                                    this.currentPanel.runningStatus = 2;
                                })
                                .catch((error) => {
                                    window.console.log(error);
                                });
                        });
                        this.currentPanel.runningMsg = res.data.msg;
                    })
                this.$refs['change-bed-modal'].hide();
            },
            rowClass(item, type) {
                if (!item || type !== 'row') return
                if (item.serial === this.currentPanel.serial) return 'table-success'
            },
            sortCompare(aRow, bRow, key, sortDesc) {
              if (key === 'bed') {
                let orders = ['dorm', 'room', 'bed'];
                let dormOrders = {
                  '一宿': 1,
                  '二宿': 2,
                  '忠孝樓': 3
                };

                for (let order of orders) {
                  let a = aRow[order];
                  let b = bRow[order];
                  if (order === 'dorm') {
                    a = dormOrders[a];
                    b = dormOrders[b];
                    window.console.log(sortDesc);
                  }

                  if (a !== b) {
                    return a < b ? -1 : a > b ? 1 : 0;
                  }
                }
                return 0;
              }
              else {
                return false;
              }
            }
        },
        data: function () {
            return{
                changeBed:{
                    oriSerial: '',
                    newBed: '',
                    show: false,
                    isStandBy: false
                },
                currentPanel:{
                    serial: "",
                    runningStatus: 0,
                    runningMsg: ""
                },
                leftList:{
                    history: [
                    ],
                    fields: [
                        {
                            key: "serial",
                            label: "名單"
                        },
                        {
                            key: "show_details",
                            label: "操作"
                        }
                    ],
                    currentPage: 1
                },
                rightResults:{
                    search:{
                        option:[
                            "學號",
                            "證件號碼"
                        ],
                        dormOption:[
                            "全部",
                            "一宿",
                            "二宿",
                            "忠孝樓"
                        ],
                        current: "學號",
                        keyword: "",
                        dormCurrent: "全部",
                        room: "",
                        filterSelect: '全部狀態'
                    },
                    results:{
                        currentPage: 1,
                        perPage: 10,
                        lists:[],
                        originData: [],
                        fields: [
                            { key: 'id', label: '資料ID'},
                            { key: 'stuId', label: '學號'},
                            { key: 'name', label: '姓名'},
                            { key: 'bed', label: '床位', sortable: true},
                            { key: 'status', label: '狀態'},
                            { key: 'actions', label: '操作' }
                        ],
                    },
                    listAction:{
                        current:"",
                        actions:[
                            {value: "", text: ""},
                            {value: "1", text: "刪除所有人，包含已入住的（刪除住宿記錄（連查詢都查不到），但帳號不會清除）"},
                            {value: "2", text: "刪除未入住的人"},
                            {value: "3", text: "全部報到（仍會檢查床位衝突）"},
                            {value: "4", text: "所有已入住的全部離宿"}
                        ]
                    }

                }
            }
        },
        components: {
            'lockRoom': lockRoom
        }
    }
</script>

<style scoped>

</style>